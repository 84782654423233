.BigButton, .BigButton-clicked {
    text-align: center;
    vertical-align: middle;
    box-shadow: 0 2px 4px 0 #9dbfaf;
    background-color: #04b463;
    height: 48px;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    border-radius: 15px;
    margin-top: 30px;
    padding: 10px;
}

.BigButton-clicked {
    background-color: #0a5331; 
}