.StickyMenuTop{
    position: fixed;
    top: 0;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    width: 100%;
    z-index: 100;
}

.AppLogo {
    padding-top: 5px;
}

.AppLogo > img {
    height: 35px;
}

.CartCount {
    background-color: #04b463;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.count {
    text-align: center;
}