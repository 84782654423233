.SearchBar {
    position: fixed;
    margin-top: 0 !important;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    width: 100%;
    height: 70px;
    text-align: center;
    z-index: 100;
    top: 0;
}

.SearchBar-Input {
    background-color: #ffffff;
    background-image: url('../images/search-icon.svg');
    background-repeat: no-repeat;
    background-size: 1.5rem;
    background-position: left;
    width: 100%;
    height: 50px;
    padding-left: 35px;
    border-style: solid;
    border-color: #ffffff;
    border-width: 0.5px;
    font-size: 15px;
    font-weight: bold;
}

.SearchBar-Input:focus {
    outline: none;
}

.SearchBar-Icon {
    height: 20px;
    width: 20px;
    margin-top: 10px;
}