.Fallback {
    visibility: visible;
    width: 400px;
    height: 600px;

    position:absolute; /*it can be fixed too*/
    left:0; right:0;
    top:0; bottom:0;
    margin:auto;

    /*this to solve "the content will not be cut when the window is smaller than the content": */
    max-width:100%;
    max-height:100%;
    overflow:auto;
    text-align: center;
}

.Fallback-header {
    font-weight: bold;
    font-size: 20px;
}