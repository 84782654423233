.StickyBarBottom {
    position: fixed;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
    height: 80px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    padding-left: 20px;
    padding-right: 20px;
}

.StickyBarBottom-Add {
    width: 100%;
    background-color:#04b463;
    border: none;
    margin-top: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #ffffff;
    font-weight: bold;
    font-size: 15px;
    border-radius: 10px;
}