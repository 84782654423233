.CategoryItem {
    margin-top: 10px;
}

.CategoryItem-Image {
    height: 110px;
    width: 110px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: #ffffff;
}


.EmptyCategoryItem-Content {
  height: 110px;
  width: 110px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 5px;
  padding-top: 10px;
  padding-left: 5px;
  margin-top: 10px;
}

.EmptyCategoryItem-Name {
  background-color: #4a4a4a;
  height: 15px;
  width: 70px;
  border-radius: 5px;
}

.EmptyCategoryItem-Image {
  height: 75px;
  width: 100px;
  margin-top: 5px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  border-radius: 5px;
  background-color: #4a4a4a;
}

.CategoryItem-Name {
    font-weight: bold;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #4a4a4a;
    padding-top: 5px;
    padding-left: 5px;
}

.CategoryItem-Tagline {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #777777;
}

.fade-in-bck {
	-webkit-animation: fade-in-bck 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fade-in-bck 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.loading {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px; 
  
  animation-duration: 2s;
  animation-fill-mode: forwards; 
  animation-iteration-count: infinite;
  animation-name: placeholderShimmer;
  animation-timing-function: linear;
  }

  @keyframes placeholderShimmer {
      0% {
        background-position: -468px 0;
      }
      
      100% {
        background-position: 468px 0; 
      }
    }

@-webkit-keyframes fade-in-bck {
    0% {
      -webkit-transform: translateZ(80px);
              transform: translateZ(80px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-bck {
    0% {
      -webkit-transform: translateZ(80px);
              transform: translateZ(80px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      opacity: 1;
    }
  }
  