.OTPCode, .OTPCode-error{
    width: 80%;
    height: 40px;
    font-size: 16px;
    font-weight: bold;
    border: solid 1px #a8a8a8;
    border-radius: 8px;
    background-color: #f5f5f5;
    color: #000000;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 15px;
    letter-spacing: 15px;
    text-align: center;
}

.OTPCode::placeholder {
    color: #a8a8a8;
}

.OTPCode-error {
    border-color: #e54c3d;
    border-width: 1.5px;
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    background-image: url('../images/error-indicator.png');
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: 95% 50%;
}