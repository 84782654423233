.OfferItem {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
}

.OfferItem-content {
    background-color: #ffffff;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.OfferItem-title {
    font-weight: bold;
    color: #04b463;
    margin-bottom: 10px;
}

.OfferItem-description {
    font-size: 13px;
    font-weight: 500;
}

.OfferItem-image {
    margin-bottom: 10px;
}

.OfferItem-image > img {
    border-radius: 10px;
}

.OfferItem-link {
    border: none;
    outline: none;
    background-color: #04b463;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.OfferItem-link > svg {
    font-size: 15px;
    color: #ffffff;
}