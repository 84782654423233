.PaymentPrompt.modal-content {
    background-color: #ffffff;
    height: 270px;
    border-radius: 8px;
    -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.PaymentPrompt-header {
    text-align: left;
    margin-top: 10px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 1rem;
}

.PaymentPrompt-info {
    font-weight: 600;
}

.PaymentPrompt-info.highlighted {
    color:#04b463;
    font-weight: bold;
}

.PaymentPrompt-amount {
    font-size: 25px;
    font-weight: bolder;
    color:#04b463;
}

.PaymentPrompt-company {
  font-size: 13px;
  font-weight: bold;
}

.PaymentPrompt-company > span {
  color:#04b463;
  font-weight: bolder;
} 

.PaymentPrompt-cta {
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 10px;
}

.PaymentPrompt-cta > button {
    width: 100%;
    background-color:#04b463;
    border: none;
    margin-top: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #ffffff;
    font-weight: bold;
    font-size: 15px;
    border-radius: 5px;
}

/* Ripple effect */
.ripple {
    background-position: center;
    transition: background 0.8s;
    border: none;
    border-radius: 2px;
    padding: 12px 18px;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    background-color: #04b463;
    box-shadow: 0 0 2px #999;
    outline: none;
  }
  .ripple:hover {
    background: #04b463 radial-gradient(circle, transparent 1%, #04b463 1%) center/15000%;
  }
  .ripple:active {
    background-color: #F5F5F5;
    background-size: 100%;
    transition: background 0s;
  }

  .ripple:disabled,
.ripple[disabled]{
  background-color: #cccccc;
  color: #666666;
}
  
  /* Button style */
  

hr {
    margin-top: 10px;
}



.PaymentPrompt-progress { 
    height: 5px;
    position: relative;
    background: #f3efe6;
    overflow: hidden;
    border-radius: 0;
    margin-top: -25px;
    margin-bottom: 15px;
}

.PaymentPrompt-progress span {
    display: block;
    height: 100%;
}

.progress {
    background-color: #04b463;
    animation: progressBar 30s ease-in-out;
    animation-fill-mode:both; 
}


@keyframes progressBar {
    0% { width: 0; }
    100% { width: 100%; }
  }

@-webkit-keyframes progressBar {
  0% { width: 0; }
  100% { width: 100%; }
}

 @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-40px);
              transform: translateY(-40px);
    }
  }
  