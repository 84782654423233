.SettingMenuItem {
    border: solid;
    border-width: 1px;
    border-color: #f5f5f5;
    border-top: none;
    border-left: none;
    border-right: none;
    padding-top: 15px;
    padding-bottom: 15px;
}

.SettingMenuItem-icon {
    padding-left: 10px;
}

.SettingMenuItem-icon > svg {
    color: #1d1d1d;
}

.SettingMenuItem-title {
    float: left;
    color: #131313;
    padding-left: 5px;
    font-weight: bold;
    font-size: 15px;
}

.SettingMenuItem-outbound {
    float: right;
    padding-right: 10px;
    color: #1d1d1d;
}

.SettingMenuItem-outbound > svg {
    width: 13px;
    height: 13px;
}