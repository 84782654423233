.OrderDetails {
    overflow: hidden;
    padding-top: 130px;
    padding-bottom: 100px;
}

.OrderDetails-header {
    margin-left: 10px;
    font-weight: bold;
    color: #777777;
}

.OrderDetails-content {
    margin-top: 20px;
    margin-bottom: 20px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.OrderDetails-Reference {
    background-color: #ffffff;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    border-style: dashed;
    border-width: 1px;
    border-color: #5A9E4E;
}