.Home {
    padding-top: 140px;
}

.header-Home {
    margin-left: 10px;
    font-weight: bold;
}

.content-Home {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 50px;
}