.Products {
    padding-top: 70px;
    padding-bottom: 70px;
}

.header-Products {
    margin-top: 60px;
    margin-left: 20px;
    font-weight: bold;
}

.content-Products {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    padding-right: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 100px;
    padding-bottom: 25px;
}