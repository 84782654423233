.Account {
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 130px;
    padding-bottom: 100px;
}

.Account-header {
    height: 100%;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin-bottom: 20px;
}

.hidden {
    display: none;
}

.visible {
    display: inline;
}