.Contact {
    padding-top: 120px;
}

.Contact-name, .Contact-number {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.Contact-name > input, .Contact-number > input {
    min-width: 100%;
    width: auto;
    height: 50px;
    border-style: none;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    padding-left: 10px;
    font-size: 14px;
}

.Contact-label {
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 15px;
    color: #777777;
    font-weight: bold;
}
