.AddressItem {
    border-bottom: solid;
    border-width: 1px;
    border-color: #f5f5f5;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.AddressItem-content {
    padding-left: 10px;
    background-color: #ffffff;
    padding-top: 15px;
    padding-bottom: 15px;
    border-radius: 8px;
}

.AddressItem-description {
    font-weight: bold;
}

.AddressItem-region {
    font-size: 15px;
}

.AddressItem-icon {
    margin-top: 15px;
}

.AddressItem > a {
    color: #131313 !important;
    font-weight: normal;
}

