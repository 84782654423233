.Login {
   padding-right: 16px;
   padding-left: 16px;
}

.Login-header > H1 {
   font-family: Arial, Helvetica, sans-serif;
   font-size: 32px;
   font-weight: bold;
   font-stretch: normal;
   font-style: normal;
   line-height: normal;
   letter-spacing: normal;
   color: #131313;
   margin-bottom: 50px;
   padding-top: 100px;
}

.Login-description {
   margin-top: 10px;
   color: #777777;
   line-height: 1.3;
   font-size: 13px;
}