.ProductDetails {
    text-align: center;
    margin-top: 10%;
}


.ProductDetails-Title {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #131313;
}

.ProductDetails-content {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding-bottom: 20px;
}

.ProductDetails-Image > img {
    margin-top: 100px;
    margin-bottom: 20px;
    width: 200px;
}

.ProductDetails-Price > span {
    font-size: 20px;
    color: #04b463;
    font-weight: bold;
}

.ProductDetails-Description > span, .ProductDetails-Name > span {
    font-weight: bold;
}

.ProductDetails-Description {
    font-size: 12px;
    margin-top: 5px;
}

.icon {
    color: #cec9c9;
}

.ProductDetails-footer {
    position: fixed;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
    height: 80px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    padding-left: 20px;
    padding-right: 20px;
}

.ProductDetails-footer > :nth-child(1) {
    margin-top: 15px;
    width: 100%;
}