.Final {
    padding-top: 130px;
}
.Final-content {
    height: 350px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    padding-top: 50px;
    padding-left: 10px;
}

.Final-content > img {
    margin-top: 10px;
}

.Final-confirmed {
    color: #04b463;
    font-weight: bold;
    font-size: 25px;
}

.Final-thankyou {
    margin-top: 10px;
    font-weight: bolder;
    color: #131313
}

.Final-OrderReference {
    margin-top: 15px;
}

.Final-OrderReference > span {
    border-style: dashed;
    border-width: 1px;
    border-color: #5A9E4E;
    border-radius: 8px;
    padding: 10px;
}