.DeliveryMethod {
    text-align: center;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}


.DeliveryMethod-card {
    background-color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
    border-color: #ffffff;
    border-style: solid;
    border-width: 1.3px;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.DeliveryMethod-card.express {
    margin-right: 2.5px;
}

.DeliveryMethod-card.scheduled {
    margin-left: 2.5px;
}

.DeliveryMethod-card.selected {
    border-color: #04b463;
    background-color: #04b463;
}

.DeliveryMethod-card.disabled {
    background-color: #e2e2e2;
    border-color: #e2e2e2;
}


.DeliveryMethod-text {
    font-size: 20px;
    font-weight: bold;
}

.DeliveryMethod-text.selected {
    color: #ffffff;
}

.DeliveryMethod-price {
    color: #04b463;
    font-weight: bold;
}

.DeliveryMethod-price.selected {
    color: #ffffff;
}