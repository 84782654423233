.SupportButton-content {
    background-color: #49A142;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 120px;
    color: #ffffff;
    border-radius: 8px;
    padding-left: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    
}  

.SupportButton-header {
    margin-top: 20px;
    margin-left: 10px;
    font-weight: bold;
}

a, a:visited, a:hover {
    color: #ffffff;
    font-weight: bold;
}