.ReceiptItem {
    border: solid;
    border-width: 1px;
    border-color: #f5f5f5;
    border-top: none;
    border-left: none;
    border-right: none;
    padding-bottom: 10px;
    padding-top: 10px;
}

.ReceiptItem-tag {
    padding-left: 20px;
    color: #131313;
    font-weight: bold;
}

.ReceiptItem-value {
    float: right;
    padding-right: 20px;
    color: #04b463;
    font-weight: bold;
}