.EmptyProductItem {
    margin-top: 25px;
    margin-left: 10px;
}

.EmptyProductItem-image {
    width: 80px;
    height: 80px;
    background-color: #dfdddd;
    border-radius: 5px;
}

.EmptyProductItem-text {
    width: 150px;
    height: 20px;
    background-color: #dfdddd;
    float: left;
    margin-top: 15px;
    border-radius: 5px;
}


.EmptyProductItem-subtext {
    width: 100px;
    height: 10px;
    background-color: #dfdddd;
    float: left;
    margin-top: 10px;
    border-radius: 5px;
}

.shine {
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px; 
    display: inline-block;
    position: relative; 
    
    animation-duration: 1s;
    animation-fill-mode: forwards; 
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    }

    @keyframes placeholderShimmer {
        0% {
          background-position: -468px 0;
        }
        
        100% {
          background-position: 468px 0; 
        }
      }
  