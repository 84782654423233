.Address {
    padding-top: 120px;
}

.Address-content {
    padding-bottom: 120px;
}

.Address-label {
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 15px;
    color: #777777;
    font-weight: bold;
}

.Address-regions > svg {
    float: right;
    position: absolute;
}

select {
    width: 100%;
    height: 50px;
    font-size: 14px;
    font-weight: bold;
    color: #131313;
    background-color: white;
    padding-left: 10px;
    appearance: none;
    border-style: none;
}
select:focus {
    min-width: 100%;
    width: auto;
    height: 50px;
}

.Address-regions, .Address-type, .Address-description {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.Address-instructions > textarea, .Address-text > textarea {
    min-width: 100%;
    width: auto;
    height: 70px;
    border-style: none;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    padding-top: 10px;
    padding-left: 10px;
    font-size: 14px;
}

.Address-description > input {
    min-width: 100%;
    width: auto;
    height: 50px;
    border-style: none;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    padding-left: 10px;
    font-size: 14px;
}