.BasketItem {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    border: solid;
    border-width: 1px;
    border-color: #f5f5f5;
    border-top: none;
    border-right: none;
    border-left: none;
    margin-left: 8px;
    margin-right: 8px;
}

.BasketItem-Image {
    border: solid;
    border-color: #8F8F8F;
    border-width: 1px;
    float: left;
    padding: 5px;
    border-radius: 8px;
}

.BasketItem-Image > img {
    width: 40px;
    height: 40px;
}

.BasketItem-Name {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #131313;
}

.BasketItem-Price {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #04b463;
}

.BasketItem-Controls {
    padding-right: 10px;
}

.BasketItem-Count {
    font-weight: bold;
    font-size: 16px;
    text-align: center;
}

.BasketItem-Increase, .BasketItem-Decrease{
    border: none;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #04b463;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #ffffff;
    font-weight: bold;
    font-size: 15px;
}