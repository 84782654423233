.Receipt {
    
}

.Receipt-header {
    color: #777777;
    font-weight: bold;
    margin-bottom: 10px;
    margin-left: 10px;

}

.Receipt-content {
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin-right: 8px;
    margin-left: 8px;
}