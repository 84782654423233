.StickyBarTop {
    position: fixed;
    top: 0;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    width: 100%;
    height: 50px;
    text-align: center;
    z-index: 100;
}

.StickyBarTop-icon, .StickyBarTop-back {
    padding-right: 15px;
    font-size: 20px;
}

.StickyBarTop-noback {
    visibility: collapse;
}

.StickyBarTop-back > svg {
    color: #777777;
}