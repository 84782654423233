.StickyMenuBottom {
    position: fixed;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
    height: 70px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
}

.column.is-one-quarter {
    text-align: center;
    padding-top: 25px;
    padding-right: 20px;
    font-size: 13px;
}


.icon-init {
    font-size: 30px;
    color: #777777;
}

.icon-highlight {
    font-size: 30px;
    color: #04b463;
}

button {
    border: none;
    outline: none;
    background-color: transparent;
}