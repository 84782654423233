.Affiliates {
    padding-top: 120px;
}

.Affiliates-Content {
    text-align: center;
}


.Affiliates-Image > img{
    width: 10rem;
    margin-top: 10px;
}

.Affiliates-Text {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
    
}
.Affiliates-Button > button {
    color: #ffffff;
    font-weight: bold;
    font-size: 15px;
    background-color: #04b463;
    padding: 15px;
    border-radius: 5px;
    border: none;
    outline: none;
    margin-top: 15px; 
}

.Affiliates-Link {
    background-color: #ffffff;
    padding-left: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
    border-style: dashed;
    border-width: 1px;
    border-color: #5A9E4E;
}