.AccountItem {
    margin-left: 15px;
}

.AccountItem-header {
    font-size: 13px;
    color: #777777;
}

.AccountItem-content {
    font-size: 16px;
    color: #131313;
}

.AccountItem-icon {
    float: right;
    padding-right: 20px;
    padding-top: 10px;
    color: #1d1d1d;
}

.AccountItem-icon > svg {
    width: 13px;
    height: 13px;
}
