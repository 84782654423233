.Affiliate {
    padding-top: 120px;
}

.Affiliate-name, .Affiliate-number {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.Affiliate-name > input, .Affiliate-number > input, .Affiliate-region > select {
    min-width: 100%;
    width: auto;
    height: 50px;
    border-style: none;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    padding-left: 10px;
    font-size: 14px;
}


.Affiliate-label {
    margin-bottom: 10px;
    margin-left: 10px;
    font-size: 15px;
    color: #777777;
    font-weight: bold;
}

.Affiliate-Link {
   
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-weight: bold;
    color: #04b463;
}


.modal {
    padding-left: 20px;
    padding-right: 20px;
}


.modal-content-bg {
    background-color: #ffffff;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-right: 20px;
    border-radius: 8px;
    text-align: center;
    z-index: 1000;
}