.subs-content {
    padding-top: 120px;
    padding-left: 8px;
    padding-right: 8px;
}

.subs-message {
    background-color: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 5px;
    font-weight: 600;
    margin-bottom: 10px;
}

.subs-header {
    color: #777777;
    font-weight: 700;
}

.subs-footer {
    padding-bottom: 100px;
}