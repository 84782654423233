.ProductItem {
    margin-top: 25px;
    margin-left: 10px;
}

.ProductItem-Image {
    border-radius: 8px;
    width: 70px;
    height: 70px;
    -webkit-animation: fade-in 1.2s cubic-bezier(0.445, 0.050, 0.550, 0.950) both;
	        animation: fade-in 1.2s cubic-bezier(0.445, 0.050, 0.550, 0.950) both;
}

.ProductItem-Name {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #131313;
    margin-bottom: 5px;
}

.ProductItem-Description {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #777777;
    margin-bottom: 5px;
}

.ProductItem-Price, .ProductItem-Price-Discount, .ProductItem-Price-Old {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
}

.ProductItem-Price {
    color: #434343;
}

.ProductItem-Price-Discount {
    color: red;
}

.ProductItem-Price-Old {
  text-decoration: line-through;
}

.hidden {
  visibility: hidden;
}

.ProductItem-Add {
    border: none;
    font-size: 16px;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(0, 72, 39, 0.3);
    background-color: #04b463;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    padding-left: 20px;
    float: right;
    margin-right: 15px;
}

@-webkit-keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  