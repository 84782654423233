.ProductThumbnail {
    margin-top: -10px;
    margin-left: 5px;
}

.ProductThumbnail-Image > img {
    width: 70px;
    height: 70px;
    border-radius: 8px;
}

.ProductThumbnail-Price {
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #04b463;
    margin-left: 10px;
}