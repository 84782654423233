.Basket {
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin-bottom: 100px;
    padding-bottom: 15px;
    overflow-x: hidden;
    overflow-y: hidden;
}

.Basket-content {
    padding-top: 130px;
}

.EmptyBasket-content {
    padding-top: 130px;
    text-align: center;
}

.EmptyBasket-message {
    color: #777777;
    font-weight: bold;
    font-size: 20px;
}

.EmptyBasket-browse {
    margin-top: 20px;
}

.EmptyBasket-browse > button {
    color: #ffffff;
    font-weight: bold;
    font-size: 15px;
    background-color: #04b463;
    padding: 15px;
    border-radius: 5px;
    border: none;
    outline: none;
}
