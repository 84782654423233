.Checkout {
    overflow-x: hidden;
}

.Checkout-content {
    padding-top: 120px;
    margin-bottom: 100px;
}

.Checkout-units, .Checkout-addresses, .Checkout-contacts, .Checkout-delivery {
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    margin-right: 8px;
    margin-left: 8px;
    margin-bottom: 10px;
}

.Checkout-newAddress, .Checkout-newContact {
    height: 60px;
    padding-top: 20px;
    padding-left: 20px;
    color: #777777;
    font-weight: bold;
}

.Checkout-contacts > select, .Checkout-addresses > select, .Checkout-delivery > select {
    border-radius: 8px;
}



.Checkout-units-header, .Checkout-address-header, .Checkout-contact-header, .Checkout-delivery-header {
    color: #777777;
    font-weight: bold;
    margin-left: 10px;
    margin-bottom: 10px;
}

.Checkout-receipt {
    margin-bottom: 30px;
}

.Checkout-units {
    margin-bottom: 20px;
}