.StatusItem {
    
}

.StatusItem-content {
    background-color: #ffffff;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.StatusItem-header {
    color: #777777;
    font-weight: bold;
    margin-left: 10px;
}

.StatusItem-title {
    color: #131313;
    font-weight: bold;
    margin-top: 5px;
}

.StatusItem-status {
    float: right;
    margin-right: 20px;
    background-color: #04b463;
    color: #ffffff;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 8px;
    font-weight: bold;
}