.BrandItem {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    height: 70px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
    margin-top: 20px;
}

.BrandItem-Name {
    margin-left: 10px;
    margin-top: 10px;
    font-weight: bold;
    color: #131313;
}

.BrandItem-Image {
    height: 100%;
    background-image: url('https://pngimage.net/wp-content/uploads/2018/06/logo-indomie-png-5.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    margin-right: 10px;
}