.OrderUnitItem {
    padding-left: 10px;
}

.OrderUnitItem-Image {
    width: 70px;
    height: 70px;
    border: solid;
    border-color: #8F8F8F;
    border-width: 0.5px;
    border-radius: 8px;
    padding: 10px;
}

.OrderUnitItem-Name {
    margin-top: 10px;
    font-weight: bold;
}

.OrderUnitItem-Price {
    color: #04b463;
    font-weight: bold;
}

.OrderUnitItem-Quantity {
    width: 35px;
    margin-top: 15px;
    font-weight: bold;
    border: solid;
    border-width: 1px;
    float: right;
    margin-right: 30px;
    background-color: #04b463;
    color: #ffffff;
    padding: 5px;
    border-radius: 50%;
    text-align: center;
}