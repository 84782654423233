.PhoneNumber, .PhoneNumber-error {
    width: 100%;
    height: 50px;
    font-size: 16px;
    border: solid 1px #a8a8a8;
    border-radius: 8px;
    background-color: #f5f5f5;
    color: #000000;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 50px;
    background-image: url('../images/kenya_flag_icon.png');
    background-repeat: no-repeat;
    background-size: 25px 25px;
    background-position: 5% 50%;
}

.PhoneNumber-error {
    border-color: #e54c3d;
    border-width: 1.5px;
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    background-image: url('../images/kenya_flag_icon.png'), url('../images/error-indicator.png');
    background-size: 25px 25px;
    background-repeat: no-repeat, no-repeat;
    background-position: 5% 50% , 95% 50%;
}

.PhoneNumber::placeholder {
    color: #a8a8a8;
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }