.modal {
  padding-top: 200px;
}

.OTPPrompt.modal-content {
    background-color: #ffffff;
    height: 200px;
    border-radius: 8px;
    margin-left: 0px;
    margin-right: 0px;
    -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.OTPPrompt-header {
    text-align: left;
    margin-top: 10px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 1rem;
}

.OTPPrompt-value > input::placeholder{
  font-weight: normal;
}

.OTPPrompt-value > input {
    width: 80%;
    height: 50px;
    font-size: 20px;
    font-weight: bold;
    border: solid 1px #a8a8a8;
    border-radius: 8px;
    background-color: #f5f5f5;
    padding-left: 15px;
    letter-spacing: 15px;
    text-align: center;
}

.OTPPrompt-cta > button {
    width: 80%;
    background-color:#04b463;
    border: none;
    margin-top: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #ffffff;
    font-weight: bold;
    font-size: 15px;
    border-radius: 5px;
}

/* Ripple effect */
.ripple {
    background-position: center;
    transition: background 0.8s;
    border: none;
    border-radius: 2px;
    padding: 12px 18px;
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
    color: white;
    background-color: #04b463;
    box-shadow: 0 0 2px #999;
    outline: none;
  }
  .ripple:hover {
    background: #04b463 radial-gradient(circle, transparent 1%, #04b463 1%) center/15000%;
  }
  .ripple:active {
    background-color: #F5F5F5;
    background-size: 100%;
    transition: background 0s;
  }

  .ripple:disabled,
.ripple[disabled]{
  background-color: #cccccc;
  color: #666666;
}
  
  /* Button style */
  

hr {
    margin-top: 10px;
}

 @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100px);
              transform: translateY(-100px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-40px);
              transform: translateY(-40px);
    }
  }
  