.OrderItem {
    background-color: #ffffff;
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
}

.OrderItem-Reference {
    color: #131313;
    font-weight: bold;
    padding-top: 15px;
}

.OrderItem-Date {
    font-weight: bold;
    font-size: 15px;
}

.OrderItem-Price {
    margin-top: 10px;
}

.OrderItem-Arrow {
    margin-top: 15px;
}
.OrderItem-Arrow > svg {
    color: #131313;
}

.OrderItem-Price {

    background-color: #04b463;
    color: #ffffff;
    font-size: 15px;
    font-weight: bold;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    padding-left: 5px;
    float: right;
    border-radius: 5px;
}