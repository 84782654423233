.Promocode {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
}

.Promocode-content {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 72, 39, 0.3);
    border-radius: 8px;
}

.Promocode-Text , .Promocode-Icon > svg{
    color: #767676;
    font-weight: bold;
}

.Promocode-Icon {
    box-shadow: 0 2px 10px 0 rgba(0, 72, 39, 0.3);
    padding: 5px 10px 5px 10px;
    border-radius: 5px;
}
