.ReactiveButton-active {
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(0, 72, 39, 0.3);
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 15px;
    padding-left: 15px;
    float: right;
    margin-right: 15px;
    background-color: #04b463;
}

.ReactiveButton-active > button, .ReactiveButton-activeLong > button {
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    border: none;
    outline: none;
    background-color: #04b463;
}

.ReactiveButton-activeLong {
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 2px 10px 0 rgba(0, 72, 39, 0.3);
    background-color: #04b463;
}

.ReactiveButton-activeLong > button {
    padding-top: 15px;
    padding-bottom: 15px;
}


.ReactiveButton-Decrease , .ReactiveButton-Increase {
    border: none;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #04b463;
    color: #ffffff;
    font-weight: bold;
    font-size: 15px;
    width: 40px;
    height: 40px;
}

.ReactiveButton-Decrease {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.ReactiveButton-Increase {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}



.ReactiveButton-Qty {
    color: #000000;
    font-weight: bold;
    margin-top: 8px;
    margin-left: 10px;
}