.EmptyBrandItem {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    height: 100px;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
    margin-top: 10px;
    padding-top: 30px;
    padding-left: 10px;
}

.EmptyBrandItem-text {
    width: 150px;
    height: 20px;
    border-radius: 5px;
    background-color: #dfdddd;
}

.shine {
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px; 
    
    animation-duration: 1s;
    animation-fill-mode: forwards; 
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
    }

    @keyframes placeholderShimmer {
        0% {
          background-position: -468px 0;
        }
        
        100% {
          background-position: 468px 0; 
        }
      }