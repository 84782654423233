.subitem {
    
}

.subitem-content {
    background-color: #ffffff;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
}

.subitem-input {
    width: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    float: right;
    text-align: center;
    border-color: #04b463;
    outline:none;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
}

.subitem-title {
    color: #131313;
    font-weight: 600;
}

.subitem-price {
    color: #04b463;
    font-weight: 700;
}