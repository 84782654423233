.Redeem {
}

.Redeem-Content {
    text-align: center;
}

.Redeem-Image {
    padding-top: 150px;
}

.Redeem-Image > img {
    width: 200px;
}

.Redeem-Input > input, .Redeem-Input-Error > input {
    width: 80%;
    height: 50px;
    font-size: 16px;
    border: solid 1px #a8a8a8;
    border-radius: 5px;
    background-color: #ffffff;
    color: #000000;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 15px;
    margin-bottom: 10px;
}

.Redeem-Input > input, .Redeem-Input-Error > input {
    text-transform: uppercase;
}

.Redeem-Input > input::placeholder, .Redeem-Input-Error > input::placeholder {
    text-transform: none;
}

.Redeem-Input-Error > input {
    border-color: #e54c3d;
    border-width: 1.5px;
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    background-image: url('../images/error-indicator.png');
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: 95% 50%;
}

.Redeem-Button > button {
    width: 80%;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    border: none;
    outline: none;
    background-color: #04b463;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 5px;
}

.Redeem-Text {
    margin-top: 5px;
    padding-left: 35px;
    padding-right: 25px;
    text-align: left;
    font-size: 12px;
    color: #777777;
}