.EmptyContact-content {
    text-align: center;
}

.EmptyContact-content > img {
    height: 200px;
}

.EmptyContact-message {
    color: #777777;
    font-weight: bold;
    font-size: 15px;
}

.EmptyContact-add {
    margin-top: 20px;
}

.EmptyContact-add > button {
    color: #ffffff;
    font-weight: bold;
    font-size: 15px;
    background-color: #04b463;
    padding: 15px;
    border-radius: 5px;
    border: none;
    outline: none;
}
